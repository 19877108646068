













































































































import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    changed() {
      this.$emit('input', !this.value);
    },
    accepted() {
      this.$emit('accepted');
      this.changed();
    },
    declined() {
      this.$emit('declined');
      this.changed();
    },
  },
});
