










































import { AxiosError } from 'axios';
import Vue from 'vue';
import { mapActions } from 'vuex';
import AuthWrapper from './AuthWrapper.vue';

export default Vue.extend({
  components: {
    AuthWrapper,
  },
  data() {
    return {
      valid: false,
      success: false,
      loading: false,
      emailNotExistError: false,

      email: '',
    };
  },
  computed: {
    emailRules(): any {
      return [
        (v: any) => !!v || this.$t('Account.E-mail is required'),
        (v: any) => /.+@.+/.test(v) || this.$t('Account.E-mail must be valid'),
        () =>
          !this.emailNotExistError ||
          this.$t('Account.There is no active user associated with this e-mail address'),
      ];
    },
  },
  methods: {
    ...mapActions(['showSuccessSnackbar', 'showFailedSnackbar']),
    resetPassword() {
      this.emailNotExistError = false;
      const isValid = (this.$refs.form as any).validate();

      if (isValid) {
        this.loading = true;

        this.$http.user
          .userPasswordResetCreate({ email: this.email })
          .then(() => {
            this.showSuccessSnackbar(this.$t('Snackbar.Success'));
            this.success = true;
          })
          .catch((err: AxiosError) => {
            if (err.response?.data?.email?.length) {
              this.emailNotExistError = true;
              this.showFailedSnackbar(this.$t('Snackbar.Invalid'));
            } else {
              this.showFailedSnackbar(this.$t('Snackbar.Something went wrong'));
            }
            (this.$refs.form as any).validate();
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
});
