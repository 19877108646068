










































































































































































import { initializeUnits } from '@/core/UnitsInitializer';
import { BussinessType } from '@/models/BusinessTypeEnum';
import { Select } from '@/modules/viewer/account/containers/AccountChangeTab.vue';
import { AxiosError } from 'axios';
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import { mapActions, mapMutations, mapState } from 'vuex';
import { emailRules, nipRules, passwordRules, phoneRules, policyRules } from '../forms/authRules';
import AuthWrapper from './AuthWrapper.vue';
import PrivacyPolicyDialog from './PrivacyPolicyDialog.vue';

export default Vue.extend({
  components: {
    AuthWrapper,
    PrivacyPolicyDialog,
  },
  mounted(){
    this.selectedLangIndex = this.langs.findIndex((lang) => lang === this.$i18n.locale);
  },
  data() {
      return {
        step: 1,
        totalSteps: 3,

        loading: false,

        valid: false,
        emailExist: false,

        show1: false,
        show2: false,

        emailField: '',
        password: '',
        confirmPassword: '',

        firstname: '',
        lastname: '',
        phone: '',
        address1: '',
        address2: '',
        nip: '',
        companyName: '',

        acceptPolicy: false,
        policyDialog: false,

        langs: this.$i18n.availableLocales, selectedLangIndex: 0
    };
  },
  destroyed() {
    this.setSignupForm(null);
  },
  computed: {
    ...mapState('user', ['user', 'email']),
    ...mapState('auth', ['signupForm']),
    businessType: {
      get: function(): Select {
        return {
          text:
            this.$t(
              'Account.' + BussinessType[this.signupForm?.businessType as number],
            ).toString() || '',
          value: this.signupForm?.businessType as BussinessType,
        };
      },
      set: function(newValue) {
        this.setSignupForm({ ...this.signupForm, businessType: newValue });
      },
    },
    businessTypes(): Select[] {
      return Object.keys(BussinessType)
        .filter((k) => !isNaN(Number(k)))
        .map((k) => {
          return {
            text: this.$t('Account.' + BussinessType[+k]).toString(),
            value: +k as BussinessType,
          };
        });
    },
    phoneRules(): TranslateResult {
      return phoneRules(this.$i18n);
    },
    nipRules(): TranslateResult {
      return nipRules(this.$i18n);
    },
    emailRules(): TranslateResult {
      return emailRules(this.emailExist, this.$i18n);
    },
    passwordRules(): { required: any; min: any; passwordMatch: any } {
      return passwordRules(this.password, this.$i18n);
    },
    policyRules(): TranslateResult {
      return policyRules(this.$i18n);
    },
  },

  methods: {
    ...mapActions(['showSuccessSnackbar', 'showFailedSnackbar']),
    ...mapMutations('auth', ['setSignupForm']),
    readPolicy() {
      this.policyDialog = true;
    },
    changeLocale(lang: string) {
      this.$i18n.locale = lang;
      this.$vuetify.lang.current = lang;
    },
    onSignup() {
      this.emailExist = false;
      (this.$refs.form as any).validate();
      if (this.valid) {
        this.loading = true;
        this.$http.user
          .userCreate(
            {
              email: this.emailField,
              password: this.password,
              first_name: this.firstname,
              last_name: this.lastname,
              company_name: this.companyName,
              address_line1: this.address1,
              address_line2: this.address2,
              nip: this.nip,
              phone: this.phone,
              business_type: this.signupForm?.businessType,
              lang: this.$i18n.locale,
              metric_units: initializeUnits().isMetricUnits,
              accept_policy: this.acceptPolicy,
            },
            { secure: false },
          )
          .then(() => {
            this.showSuccessSnackbar(this.$t('Account.Account created'));
            this.$router.push({
              name: 'SignInOnCreate',
            });
          })
          .catch((err: AxiosError) => {
            if (err.response?.data.email.length) {
              this.showFailedSnackbar(this.$t('Account.Some of the fields needs your attention'));
              this.emailExist = true;
              (this.$refs.form as any).validate();
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    nextStep() {
        if((this.$refs.form as any).validate()) {
            this.step ++;
        }
    },
    prevStep() {
        this.step --;
    }
  },
});
