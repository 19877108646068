



































































import { LoginResponse } from '@/http/HttpResponses';
import { AuthData } from '@/store/auth';
import Vue from 'vue';
import { mapActions, mapMutations } from 'vuex';
import { injectAuthInterceptor } from '../services/auth.interceptor';
import AuthWrapper from './AuthWrapper.vue';

export default Vue.extend({
  components: {
    AuthWrapper,
  },
  props: {
    onCreated: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: '',
      password: '',
      snackbar: false,
    };
  },

  methods: {
    ...mapMutations('user', ['setUserEmail']),
    ...mapActions('auth', ['authUser']),
    ...mapActions('user', ['assignUser']),
    onSignIn() {
      const body = {
        email: this.email,
        password: this.password,
      };

      this.$http.auth
        .authTokenCreate(body, { secure: false })
        .then((resp) => {
          const { access, refresh } = (resp.data as unknown) as LoginResponse;
          const authData: AuthData = {
            userToken: access,
            refreshToken: refresh,
          };

          this.$http.setSecurityData(authData);

          injectAuthInterceptor(this.$http, refresh, () =>
            this.$router.push({
              name: 'SignIn',
              query: { redirect: this.$router.currentRoute.path },
            }),
          );

          this.authUser(authData).then(() => {
            if (this.$route.query.redirect) {
              this.$router.push({ path: this.$route.query.redirect as string });
            } else {
              this.$router.push({ name: 'Dashboard' });
            }
          });

          this.$http.user.userRead('current').then((resp) => {
            this.assignUser(resp.data);
            const lang = resp.data.lang;
            if (lang) {
              this.$i18n.locale = lang;
              this.$vuetify.lang.current = lang;
            }
          });

          this.setUserEmail(this.email);
        })
        .catch((err) => {
          this.snackbar = true;
        });
    },
  },
});
