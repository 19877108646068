




























import Vue from 'vue';
import { mapActions } from 'vuex';
import AuthWrapper from './AuthWrapper.vue';
import { AxiosError } from 'axios';

export default Vue.extend({
  components: {
    AuthWrapper,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      email: '',
      password: '',
      snackbar: false,
      accountActivateSuccess: false,
      accountActivateFailed: false,
      loading: true,
    };
  },
  mounted() {
    this.$http.user
      .userUserActivate({ token: this.id }, { secure: false })
      .then(() => {
        this.showSuccessSnackbar(this.$t('Account.Account activated'));
        this.accountActivateSuccess = true;
      })
      .catch((err: AxiosError) => {
        if (err.response?.data?.token) {
          this.showFailedSnackbar(this.$t('Account.Cannot activate'));
          this.accountActivateFailed = true;
        }
        this.showFailedSnackbar(this.$t('Something went wrong'));
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions(['showSuccessSnackbar', 'showFailedSnackbar']),
  },
});
