








































































import Vue from 'vue';
import { mapActions } from 'vuex';
import AuthWrapper from './AuthWrapper.vue';

export default Vue.extend({
  components: {
    AuthWrapper,
  },
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      valid: false,
      success: false,
      loading: false,

      email: '',
      show1: false,
      show2: false,
      newPassword: '',
      repeatPassword: '',
    };
  },
  created() {
    this.$http.user
      .userPasswordResetValidateTokenCreate({ token: this.id }, { secure: false })
      .catch(() => {
        this.showFailedSnackbar(this.$t('Account.Invalid password link or expired'));
        this.$router.push({ name: 'SignIn' });
      });
  },
  computed: {
    passwordRules(): any {
      return {
        required: (value: any) => !!value || this.$t('Account.Password is required'),
        min: (v: any) => (v && v.length >= 8) || this.$t('Account.Min 8 characters'),
        passwordMatch: (v: string) =>
          v === this.newPassword || this.$t(`Account.Passwords you entered don't match`),
      };
    },
  },
  methods: {
    ...mapActions(['showSuccessSnackbar', 'showFailedSnackbar']),
    resetPassword() {
      const isValid = (this.$refs.form as any).validate();

      if (isValid) {
        this.loading = true;

        this.$http.user
          .userPasswordResetConfirmCreate(
            { password: this.newPassword, token: this.id },
            { secure: false },
          )
          .then(() => {
            this.showSuccessSnackbar(this.$t('Snackbar.Success'));
            this.success = true;
            this.$router.push({ name: 'SignIn' });
          })
          .catch(() => {
            this.showFailedSnackbar(this.$t('Snackbar.Something went wrong'));
            (this.$refs.form as any).validate();
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
});
